// Icons
import { TbWorld } from "react-icons/tb";
import { FaRegCopyright, FaTools } from "react-icons/fa";
import { BsCurrencyDollar, BsGrid } from "react-icons/bs";
import { BiCalculator, BiCube, BiStoreAlt } from "react-icons/bi";

// Components
import { Box, Grid, Text } from "@chakra-ui/react";
import { DrawerDeskOptions } from "./DrawerDeskOptions";

// Hooks
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { LuLayoutGrid } from "react-icons/lu";
import { useState } from "react";

export const DesktopDrawer = () => {
  const [subMenu, setSubMenu] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const { id } = useParams();

  return (
    <Box
      w="100%"
      bg="#ffffff"
      gap="2"
      top="6.25rem"
      left="0"
      display="flex"
      flexDir="column"
      maxWidth="15.625rem"
      position="fixed"
      fontFamily="Poppins"
    >
      <DrawerDeskOptions
        textName="Dashboard"
        pathnames={location.pathname === "/dashboard/menu-inicial"}
        color={
          location.pathname === "/dashboard/menu-inicial"
            ? "#ffffff"
            : "#3058A4"
        }
        onClick={() => {
          navigate("/dashboard/menu-inicial");
          setSubMenu(false);
        }}
        bg={location.pathname === "/dashboard/menu-inicial" && "#3058A4"}
        hover={
          location.pathname !== "/dashboard/menu-inicial" && {
            bg: "#E0E0E0",
            transition: "ease-in 0.2s",
            transform: "scale(1.1)",
          }
        }
      >
        <BsGrid
          fill={
            location.pathname === "/dashboard/menu-inicial"
              ? "#42BBFF"
              : "#3058A4"
          }
          size="1.5rem"
        />
      </DrawerDeskOptions>

      <DrawerDeskOptions
        textName="Construtor de Lojas"
        pathnames={location.pathname === "/dashboard/construtor-de-lojas"}
        color={
          location.pathname === "/dashboard/construtor-de-lojas"
            ? "#fff"
            : "#3058A4"
        }
        onClick={() => {
          navigate("/dashboard/construtor-de-lojas");
          setSubMenu(false);
        }}
        bg={
          location.pathname === "/dashboard/construtor-de-lojas"
            ? "#3058A4"
            : ""
        }
        hover={
          location.pathname !== "/dashboard/construtor-de-lojas"
            ? {
                bg: "#E0E0E0",
                transition: "ease-in 0.2s",
                transform: "scale(1.1)",
              }
            : null
        }
      >
        <TbWorld
          color={
            location.pathname === "/dashboard/construtor-de-lojas"
              ? "#42BBFF"
              : "#3058A4"
          }
          size="24px"
        />
      </DrawerDeskOptions>

      <DrawerDeskOptions
        textName="Produtos"
        color={
          location.pathname === "/dashboard/fornecedores" ||
          location.pathname === `/dashboard/fornecedores/${id}`
            ? "#ffffff"
            : "#3058A4"
        }
        pathnames={
          location.pathname === "/dashboard/fornecedores" ||
          location.pathname === `/dashboard/fornecedores/${id}`
        }
        onClick={() => {
          navigate("/dashboard/fornecedores");
          setSubMenu(false);
        }}
        bg={
          location.pathname === "/dashboard/fornecedores" ||
          location.pathname === `/dashboard/fornecedores/${id}`
            ? "#3058A4"
            : ""
        }
        hover={
          location.pathname !== "/dashboard/fornecedores" &&
          location.pathname !== `/dashboard/fornecedores/${id}`
            ? {
                bg: "#e0e0e0",
                transition: "ease-in 0.2s",
                transform: "scale(1.1)",
              }
            : null
        }
      >
        <BiStoreAlt
          fill={
            location.pathname === "/dashboard/fornecedores" ||
            location.pathname === `/dashboard/fornecedores/${id}`
              ? "#42BBFF"
              : "#3058A4"
          }
          size="24px"
        />
      </DrawerDeskOptions>

      <DrawerDeskOptions
        textName="Ferramentas"
        color={
          location.pathname === "/dashboard/copy" ||
          location.pathname === "/dashboard/gerador-de-nomes" ||
          location.pathname === "/dashboard/calculadora" ||
          location.pathname === "/dashboard/aplicativos"
            ? "#ffffff"
            : "#3058A4"
        }
        onClick={() => {
          setSubMenu(true);
          navigate("/dashboard/copy");
        }}
        bg={
          location.pathname === "/dashboard/copy" ||
          location.pathname === "/dashboard/gerador-de-nomes" ||
          location.pathname === "/dashboard/calculadora" ||
          location.pathname === "/dashboard/aplicativos"
            ? "#3058A4"
            : ""
        }
        hover={
          location.pathname !== "/dashboard/copy" &&
          location.pathname !== "/dashboard/gerador-de-nomes" &&
          location.pathname !== "/dashboard/calculadora" &&
          location.pathname !== "/dashboard/aplicativos"
            ? {
                bg: "#e0e0e0",
                transition: "ease-in 0.2s",
                transform: "scale(1.1)",
              }
            : null
        }
      >
        <FaTools
          fill={
            location.pathname === "/dashboard/copy" ||
            location.pathname === "/dashboard/gerador-de-nomes" ||
            location.pathname === "/dashboard/calculadora" ||
            location.pathname === "/dashboard/aplicativos"
              ? "#42BBFF"
              : "#3058A4"
          }
          size="24px"
        />
      </DrawerDeskOptions>

      {subMenu && (
        <Grid
          w="100%"
          mb="0.5rem"
          pl="4rem"
          display="flex"
          flexDir="column"
          gap="0.5rem"
        >
          <Link to="/dashboard/copy">
            <Text
              fontWeight={location.pathname === "/dashboard/copy" ? 500 : 400}
              cursor="pointer"
              color={
                location.pathname === "/dashboard/copy" ? "#fb6b03" : "#3058a4"
              }
              _hover={{ opacity: 0.8 }}
            >
              Gerador de Copy
            </Text>
          </Link>
          <Link to="/dashboard/gerador-de-nomes">
            <Text
              fontWeight={
                location.pathname === "/dashboard/gerador-de-nomes" ? 500 : 400
              }
              cursor="pointer"
              color={
                location.pathname === "/dashboard/gerador-de-nomes"
                  ? "#fb6b03"
                  : "#3058a4"
              }
              _hover={{ opacity: 0.8 }}
            >
              Gerador de Nomes
            </Text>
          </Link>
          <Link to="/dashboard/calculadora">
            <Text
              fontWeight={
                location.pathname === "/dashboard/calculadora" ? 500 : 400
              }
              cursor="pointer"
              color={
                location.pathname === "/dashboard/calculadora"
                  ? "#fb6b03"
                  : "#3058a4"
              }
              _hover={{ opacity: 0.8 }}
            >
              Calculadora
            </Text>
          </Link>
          <Link to="/dashboard/aplicativos">
            <Text
              fontWeight={
                location.pathname === "/dashboard/aplicativos" ? 500 : 400
              }
              cursor="pointer"
              color={
                location.pathname === "/dashboard/aplicativos"
                  ? "#fb6b03"
                  : "#3058a4"
              }
              _hover={{ opacity: 0.8 }}
            >
              Aplicativos
            </Text>
          </Link>
        </Grid>
      )}

      <DrawerDeskOptions
        textName="Players Academy"
        color={
          location.pathname === "/dashboard/tutoriais" ||
          location.pathname === `/dashboard/tutoriais/${id}`
            ? "#ffffff"
            : "#3058A4"
        }
        pathnames={
          location.pathname === "/dashboard/tutoriais" ||
          location.pathname === `/dashboard/tutoriais/${id}`
        }
        onClick={() => {
          navigate("/dashboard/tutoriais");
          setSubMenu(false);
        }}
        bg={
          location.pathname === "/dashboard/tutoriais" ||
          location.pathname === `/dashboard/tutoriais/${id}`
            ? "#3058A4"
            : ""
        }
        hover={
          location.pathname !== "/dashboard/tutoriais" &&
          location.pathname !== `/dashboard/tutoriais/${id}`
            ? {
                bg: "#e0e0e0",
                transition: "ease-in 0.2s",
                transform: "scale(1.1)",
              }
            : null
        }
      >
        <BiCube
          fill={
            location.pathname === "/dashboard/tutoriais" ||
            location.pathname === `/dashboard/tutoriais/${id}`
              ? "#42BBFF"
              : "#3058A4"
          }
          size="1.5rem"
        />
      </DrawerDeskOptions>

      <DrawerDeskOptions
        textName="Esteira de Produtos"
        pathnames={location.pathname === "/dashboard/esteira-de-produtos"}
        color={
          location.pathname === "/dashboard/esteira-de-produtos"
            ? "#ffffff"
            : "#3058A4"
        }
        onClick={() => {
          navigate("/dashboard/esteira-de-produtos");
          setSubMenu(false);
        }}
        bg={location.pathname === "/dashboard/esteira-de-produtos" && "#3058A4"}
        hover={
          location.pathname !== "/dashboard/esteira-de-produtos" && {
            bg: "#E0E0E0",
            transition: "ease-in 0.2s",
            transform: "scale(1.1)",
          }
        }
      >
        <BiStoreAlt
          fill={
            location.pathname === "/dashboard/esteira-de-produtos"
              ? "#42BBFF"
              : "#3058A4"
          }
          size="24px"
        />
      </DrawerDeskOptions>

      <DrawerDeskOptions
        textName="Planos"
        pathnames={location.pathname === "/dashboard/planos"}
        color={
          location.pathname === "/dashboard/planos" ? "#ffffff" : "#3058A4"
        }
        onClick={() => {
          navigate("/dashboard/planos");
          setSubMenu(false);
        }}
        bg={location.pathname === "/dashboard/planos" && "#3058A4"}
        hover={
          location.pathname !== "/dashboard/planos" && {
            bg: "#E0E0E0",
            transition: "ease-in 0.2s",
            transform: "scale(1.1)",
          }
        }
      >
        <BsCurrencyDollar
          color={
            location.pathname === "/dashboard/planos" ? "#42BBFF" : "#3058A4"
          }
          size="24px"
        />
      </DrawerDeskOptions>
    </Box>
  );
};
