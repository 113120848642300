import * as H from "./styles";

import { useState } from "react";

import { FiMenu } from "react-icons/fi";
import { FaCrown, FaUser } from "react-icons/fa";
import { PlaydropLogo } from "../Reusable/PlayDropLogo";
import { AiOutlineSetting } from "react-icons/ai";
import { BsBell, BsHeadset } from "react-icons/bs";

import { Link, useNavigate } from "react-router-dom";
import { DrawerLogout } from "../Drawer/DrawerLogout";
import { DashboardDrawer } from "../Drawer";
import { DrawerAdmPanelMobile } from "../Drawer/DrawerAdmPanelMobile";
import {
  Box,
  Link as LinkChakra,
  // Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import { useAuth } from "../../contexts/Auth";
// import { useCredits } from "../../contexts/Credits";
// import { DrawerCoin } from "./DrawerCoin";
import { ModalBacklog } from "../Modal/Backlog";
import { useBacklogs } from "../../contexts/Backlogs";

export const Header = () => {
  // const [loading, setLoading] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  // const [showDrawerCoin, setShowDrawerCoin] = useState(false);

  const { data } = useAuth();

  const { backlogDataUser } = useBacklogs();

  // const { totalCredits, loadCreditsUser } = useCredits();

  const signed = data && Object.values(data).length > 0;

  const navigate = useNavigate();

  const {
    isOpen: isOpenAdmin,
    onOpen: onOpenAdmin,
    onClose: onCloseAdmin,
  } = useDisclosure();

  const {
    isOpen: isOpenClient,
    onOpen: onOpenClient,
    onClose: onCloseClient,
  } = useDisclosure();

  const {
    isOpen: isOpenBacklog,
    onOpen: onOpenBacklog,
    onClose: onCloseBacklog,
  } = useDisclosure();

  const isProfileAdmin = data.perfil === "admin";

  const handleOpenDrawer = () => {
    if (isProfileAdmin) {
      onOpenAdmin();
    } else {
      onOpenClient();
    }
  };

  // useEffect(() => {
  //   setLoading(true);

  //   signed &&
  //     loadCreditsUser()
  //       .then((_) => setLoading(false))
  //       .catch((_) => setLoading(false));

  //   eslint-disable-next-line
  // }, []);

  return (
    <H.Container>
      <H.ContentLogo>
        <PlaydropLogo widthLogo="11rem" heightLogo="46px" colorLogo="#3058a4" />
      </H.ContentLogo>

      <H.ContentMobile>
        <H.MenuIcon onClick={handleOpenDrawer}>
          <FiMenu size="1.5rem" color="#fff" />
        </H.MenuIcon>

        <H.ContentIcons>
          {/* {signed && data.perfil !== "admin" && data.id_planos === 4 ? (
            <H.Icon
              className="faq"
              onMouseEnter={() => setShowDrawerCoin(true)}
              onMouseLeave={() => setShowDrawerCoin(false)}
            >
              <div className="credits-content">
                {loading ? (
                  <Spinner size="md" color="#fb6b03" />
                ) : (
                  <>
                    <BsCoin size="2rem" fill="#daa520" />
                    <span className="credits">{totalCredits}</span>
                  </>
                )}
              </div>
            </H.Icon>
          ) : null} */}

          <Box position="relative">
            <H.Icon
              onClick={() => {
                if (!isProfileAdmin) {
                  onOpenBacklog();
                } else {
                  return;
                }
              }}
            >
              <BsBell fill="#3058a4" size="1.8rem" />
            </H.Icon>

            {!isProfileAdmin && backlogDataUser.length > 0 ? (
              <Box
                w="1.8rem"
                h="1.8rem"
                position="absolute"
                top="-1rem"
                right="-0.5rem"
                bg="#fb8a03"
                color="#fff"
                borderRadius="50%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                fontSize="1.25rem"
                fontWeight="500"
              >
                {backlogDataUser.length}
              </Box>
            ) : null}
          </Box>

          {signed && data.perfil !== "admin" ? (
            <Link to="/dashboard/configuracoes">
              <H.Icon>
                <AiOutlineSetting fill="#3058a4" size="1.8rem" />
              </H.Icon>
            </Link>
          ) : null}

          {signed && data.perfil !== "admin" ? (
            <LinkChakra
              target="_blank"
              rel="noreferrer"
              href="https://playdrop.tawk.help/"
            >
              <H.Icon className="faq">
                <BsHeadset fill="#3058a4" size="1.8rem" />
                <p>Ajuda</p>
              </H.Icon>
            </LinkChakra>
          ) : null}

          <H.Icon className="drawer-icon" onClick={() => setIsDrawerOpen(true)}>
            <FaUser fill="#3058a4" size="1.5rem" />
          </H.Icon>
        </H.ContentIcons>

        {isProfileAdmin ? (
          <DrawerAdmPanelMobile isOpen={isOpenAdmin} onClose={onCloseAdmin} />
        ) : (
          <DashboardDrawer isOpen={isOpenClient} onClose={onCloseClient} />
        )}

        {isDrawerOpen ? (
          <DrawerLogout onClose={() => setIsDrawerOpen(false)} />
        ) : null}

        {/* {showDrawerCoin ? <DrawerCoin coins={totalCredits} /> : null} */}

        {!isProfileAdmin && (
          <ModalBacklog isOpen={isOpenBacklog} onClose={onCloseBacklog} />
        )}
      </H.ContentMobile>
    </H.Container>
  );
};
