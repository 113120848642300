import * as P from "./styles";

import { useProductsClient } from "../../contexts/Products/ProductsContextClient";
import { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { TbWorld } from "react-icons/tb";
import { TiThSmall } from "react-icons/ti";
import { BiStoreAlt } from "react-icons/bi";
import { FaFilter, FaChild, FaUtensils, FaSearch } from "react-icons/fa";
import { SiStylelint } from "react-icons/si";
import { FaKitchenSet } from "react-icons/fa6";
import { BiSolidShoppingBags } from "react-icons/bi";
import { GiCharm, GiConverseShoe } from "react-icons/gi";
import {
  MdOutlineGames,
  MdOutlineHealthAndSafety,
  MdPets,
} from "react-icons/md";

import { Title } from "../../components/Reusable/Title";
import { Box, Input, Spinner } from "@chakra-ui/react";
import { Container } from "../../components/Reusable/Container";
import { ProductCard } from "./ProductCard";
import { SkeletonProducts } from "../../components/Reusable/Skeletons/SkeletonProducts";
import { useCredits } from "../../contexts/Credits";
import { useFilter } from "../../hooks/useFilter";

import { createStaticRanges, DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // css básico
import "react-date-range/dist/theme/default.css"; // css tema padrão
import { ptBR } from "date-fns/locale";
import { addDays } from "date-fns";
import { useEffect } from "react";

const categories = [
  { id: 1, name: "Todos", icon: <TiThSmall size="2rem" /> },
  { id: 12, name: "Latam", icon: <TbWorld size="2rem" /> },
  { id: 2, name: "Pets", icon: <MdPets size="2rem" /> },
  { id: 3, name: "Eletrônicos", icon: <MdOutlineGames size="2rem" /> },
  { id: 4, name: "Saúde", icon: <MdOutlineHealthAndSafety size="2rem" /> },
  { id: 5, name: "Beleza", icon: <GiCharm size="2rem" /> },
  { id: 6, name: "Moda e Estilo", icon: <SiStylelint size="2rem" /> },
  { id: 7, name: "Infantil", icon: <FaChild size="2rem" /> },
  { id: 8, name: "Cozinha", icon: <FaKitchenSet size="2rem" /> },
  { id: 9, name: "Bolsas", icon: <BiSolidShoppingBags size="2rem" /> },
  { id: 10, name: "Calçados", icon: <GiConverseShoe size="2rem" /> },
  { id: 11, name: "Utensílios", icon: <FaUtensils size="2rem" /> },
];

const formatValue = (value) => {
  if (value < 1000) {
    return value;
  } else {
    const formattedValue = (value / 1000).toFixed(1);
    return `${formattedValue}k`;
  }
};

const initialEndDate = new Date();
const initialStartDate = new Date();
initialStartDate.setDate(initialStartDate.getDate() - 60);

export const Products = () => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [productName, setProductName] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [newDate, setNewDate] = useState([
    {
      startDate: initialStartDate,
      endDate: initialEndDate,
      key: "selection",
    },
  ]);

  const [visibleProducts, setVisibleProducts] = useState(12);
  const [loadLazy, setLoadLazy] = useState(false);

  const handleScroll = () => {
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight &&
      !loadLazy
    ) {
      loadMoreProducts();
    }
  };

  const loadMoreProducts = () => {
    setLoadLazy(true);
    setTimeout(() => {
      setVisibleProducts((prevVisibleProducts) => prevVisibleProducts + 12);
      setLoadLazy(false);
    }, 2000);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };

    // eslint-disable-next-line
  }, []);

  const [eventTriggered, setEventTriggered] = useState(false);

  const [value, setValue] = useState({
    reactions: 0,
    comments: 0,
    shares: 0,
  });

  const handleSelect = (ranges) => {
    const { startDate, endDate } = ranges.selection;
    setNewDate([ranges.selection]);
    setShowCalendar(false); // Fechar o calendário após a seleção
  };

  const handleInputClick = () => {
    setShowCalendar(!showCalendar);
  };

  const [showFilter, setShowFilter] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);

  const { pathname } = useLocation();
  const { credits, discountCoinWallet } = useCredits();

  const isSuppliers = pathname === "/dashboard/fornecedores";

  const filteredCredits =
    credits.length > 0 &&
    credits?.find((item) => item.category === "ver_produtos");

  const filteredCreditsConveyor =
    credits.length > 0 &&
    credits?.find((item) => item.category === "adicionar_esteira");

  const {
    searchByName,
    searchByDate,
    searchByCategory,
    searchByCriteria,
    cleanFilters,
    filteredProducts,
  } = useFilter();

  const { products } = useProductsClient();

  const staticRanges = createStaticRanges([
    {
      label: "Hoje",
      range: () => ({
        startDate: new Date(),
        endDate: new Date(),
      }),
    },
    {
      label: "Ontem",
      range: () => ({
        startDate: addDays(new Date(), -1),
        endDate: addDays(new Date(), -1),
      }),
    },
    {
      label: "Últimos 7 dias",
      range: () => ({
        startDate: addDays(new Date(), -7),
        endDate: new Date(),
      }),
    },
    {
      label: "Últimos 30 dias",
      range: () => ({
        startDate: addDays(new Date(), -30),
        endDate: new Date(),
      }),
    },
    {
      label: "Este mês",
      range: () => ({
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        endDate: new Date(),
      }),
    },
    {
      label: "Mês passado",
      range: () => ({
        startDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth() - 1,
          1
        ),
        endDate: new Date(new Date().getFullYear(), new Date().getMonth(), 0),
      }),
    },
  ]);

  useEffect(() => {
    if (
      productName !== "" ||
      selectedCategory !== null ||
      value.reactions > 0
    ) {
      setEventTriggered(true);
    }

    return () => {
      setEventTriggered(false);
    };
  }, [productName, selectedCategory, value]);

  return (
    <Container>
      {isSuppliers ? (
        <>
          <Title
            headingName="Produtos"
            caption="Veja abaixo alguns dos muitos produtos que foram a virada de chave
            financeira de muitos dropshippers. Produtos com fornecedores
            nacionais e internacionais, com frete grátis. Deixe a sua operação
            de dropshipping ainda mais poderosa com produtos vencedores."
          >
            <BiStoreAlt size="2rem" fill="#3058A9" />
          </Title>

          <hr />

          <P.Content>
            <P.Header>
              <div className="content-search">
                <div className="search">
                  <FaSearch size="1.25rem" />
                  <Input
                    pl="2rem"
                    h="2.5rem"
                    bg="#fff"
                    boxShadow="0px 8px 5px -1px #e6eeff"
                    _placeholder={{ fontSize: "0.8rem", color: "#3058a4" }}
                    placeholder="Pesquise algum produto..."
                    onChange={(ev) => {
                      setProductName(ev.target.value);
                      searchByName(ev.target.value);
                      setEventTriggered(true);
                    }}
                  />
                </div>
              </div>

              <div className="content-info">
                <div
                  className="filter"
                  onClick={() => setShowFilter(!showFilter)}
                >
                  <p>Filtros</p>
                  <FaFilter size="1.2rem" fill="#3058a4" />
                </div>
              </div>
            </P.Header>

            <hr />

            <P.CategoriesContent>
              {categories.map((item) => (
                <div
                  className={`category-card ${
                    selectedCategory === item.id ? "selected" : ""
                  }`}
                  key={item.id}
                  onClick={() => {
                    setLoading(true);
                    setSelectedCategory(item.id);

                    setTimeout(() => {
                      searchByCategory(
                        item.name === "Pets" ? "Pet" : item.name
                      );
                      setEventTriggered(true);
                      setLoading(false);
                    }, 1000);
                  }}
                >
                  <div>{item.icon}</div>
                  <p>{item.name}</p>
                </div>
              ))}
            </P.CategoriesContent>

            {showFilter && (
              <P.Root>
                <div className="content-filter-date">
                  <h4>Filtro por data</h4>

                  <div className="inputs-filter-date">
                    <input
                      type="text"
                      value={newDate[0].startDate.toLocaleDateString()}
                      readOnly
                      onClick={handleInputClick}
                    />
                    <input
                      type="text"
                      value={newDate[0].endDate.toLocaleDateString()}
                      readOnly
                      onClick={handleInputClick}
                    />
                  </div>
                  {showCalendar && (
                    <div
                      style={{
                        position: "absolute",
                        zIndex: 10,
                        top: "100%",
                      }}
                    >
                      <DateRangePicker
                        ranges={newDate}
                        onChange={handleSelect}
                        locale={ptBR}
                        staticRanges={staticRanges}
                      />
                    </div>
                  )}
                </div>

                <h4>Filtro por critérios</h4>
                <P.FilterGrid>
                  <div className="container">
                    <h4>Reações</h4>

                    <input
                      className="slider"
                      type="range"
                      min="0"
                      max="50000"
                      value={value.reactions}
                      onChange={(ev) =>
                        setValue({ ...value, reactions: ev.target.value })
                      }
                    />

                    <div className="value_label">
                      Maior ou igual a: {formatValue(value.reactions)}
                    </div>
                  </div>

                  <div className="container">
                    <h4>Comentários</h4>

                    <input
                      className="slider"
                      type="range"
                      min="0"
                      max="50000"
                      value={value.comments}
                      onChange={(ev) =>
                        setValue({ ...value, comments: ev.target.value })
                      }
                    />

                    <div className="value_label">
                      Maior ou igual a: {formatValue(value.comments)}
                    </div>
                  </div>

                  <div className="container">
                    <h4>Compartilhamentos</h4>

                    <input
                      className="slider"
                      type="range"
                      min="0"
                      max="50000"
                      value={value.shares}
                      onChange={(ev) =>
                        setValue({ ...value, shares: ev.target.value })
                      }
                    />

                    <div className="value_label">
                      Maior ou igual a: {formatValue(value.shares)}
                    </div>
                  </div>
                </P.FilterGrid>

                <div className="flex-buttons-filter">
                  <button
                    onClick={() => {
                      setLoading(true);

                      setTimeout(() => {
                        if (
                          value.reactions > 0 ||
                          value.comments > 0 ||
                          value.shares > 0
                        ) {
                          searchByCriteria(
                            value.reactions,
                            value.comments,
                            value.shares
                          );
                          setEventTriggered(true);
                        } else {
                          searchByDate(
                            newDate[0].startDate,
                            newDate[0].endDate
                          );
                          setEventTriggered(true);
                        }

                        setLoading(false);
                      }, 2000);
                    }}
                  >
                    Aplicar filtros
                  </button>

                  <button
                    className="btn-clean"
                    onClick={() => {
                      cleanFilters("criteria");
                      cleanFilters("date");
                      setShowFilter(!showFilter);
                      setEventTriggered(false);
                    }}
                  >
                    Limpar filtros
                  </button>
                </div>
              </P.Root>
            )}

            <P.Grid>
              {loading ? (
                <SkeletonProducts quantityParam={12} />
              ) : (
                <>
                  {!eventTriggered ? (
                    <>
                      {products !== undefined &&
                        products
                          .slice(0, visibleProducts)
                          .map((product) => (
                            <ProductCard
                              key={product.id_fornecedores}
                              product={product}
                              filteredCredits={filteredCredits}
                              filteredCreditsConveyor={filteredCreditsConveyor}
                              discountCoinWallet={discountCoinWallet}
                            />
                          ))}
                      {loadLazy && (
                        <Box
                          w="100%"
                          mt="20px"
                          display="flex"
                          justifyContent="center"
                        >
                          <Spinner size="xl" color="#3058a4" />
                        </Box>
                      )}
                    </>
                  ) : filteredProducts.length > 0 ? (
                    filteredProducts.map((product) => (
                      <ProductCard
                        key={product.id_fornecedores}
                        product={product}
                        filteredCredits={filteredCredits}
                        filteredCreditsConveyor={filteredCreditsConveyor}
                        discountCoinWallet={discountCoinWallet}
                      />
                    ))
                  ) : (
                    <p>Produto não encontrado</p>
                  )}
                </>
              )}
            </P.Grid>
          </P.Content>
        </>
      ) : (
        <Outlet />
      )}
    </Container>
  );
};
