import * as D from "./styles";

import { Link } from "react-router-dom";

import { TbWorld } from "react-icons/tb";
import { FaCubes, FaFire, FaUser } from "react-icons/fa";
import { BiStoreAlt } from "react-icons/bi";
import { RiLayout3Line } from "react-icons/ri";

import { useAuth } from "../../contexts/Auth";
import { useWebsitesAdmin } from "../../contexts/Websites/WebsitesContextAdmin";
import { useWebsitesClient } from "../../contexts/Websites/WebsitesContextClient";
import { useProductsClient } from "../../contexts/Products/ProductsContextClient";
import { useTutorialsClient } from "../../contexts/Tutorials/TutorialsContextClient";
import { useTemplatesClient } from "../../contexts/Templates/TemplatesContextClient";
import { useUser } from "../../contexts/User";
import { BsCurrencyDollar } from "react-icons/bs";
import { useEffect } from "react";
import { useState } from "react";

export const DashNavigation = () => {
  const { data } = useAuth();
  const { users } = useUser();
  const { tutorials } = useTutorialsClient();
  const { themes } = useTemplatesClient();
  const { websitesUser } = useWebsitesClient();
  const { websitesAdmin } = useWebsitesAdmin();
  const { products, productsConveyor } = useProductsClient();

  const [quantityProducts, setQuantityProducts] = useState(
    products.length > 1 ? products.length * 20 : 1980
  );

  const [isHidden, setIsHidden] = useState(false);

  const isAdminProfile = data.perfil === "admin";

  let productsConveyorQuantity = 0;

  for (const item in productsConveyor) {
    if (productsConveyor.hasOwnProperty(item)) {
      productsConveyorQuantity += productsConveyor[item].items.length;
    }
  }

  const usersWithBasicPlan = users?.filter(
    (user) => user.id_planos === 4
  ).length;
  const usersWithGoldPlan = users?.filter(
    (user) => user.id_planos === 5
  ).length;
  const usersWithPremiumPlan = users?.filter(
    (user) => user.id_planos === 9
  ).length;

  useEffect(() => {
    const interval = setInterval(() => {
      setIsHidden(true);
      setTimeout(() => {
        setQuantityProducts((prevQuantity) => prevQuantity + 1);
        setIsHidden(false); // Mostra o número atualizado
      }, 500);
    }, 5000);

    return () => clearInterval(interval); // Limpa o intervalo quando o componente é desmontado
  }, []);

  return (
    <D.NavContainer>
      <Link
        to={isAdminProfile ? "/dashboard/usuarios" : "/dashboard/configuracoes"}
      >
        <D.NavCard>
          <h4>{isAdminProfile ? "Basic" : "Lojas"}</h4>
          <div>
            {isAdminProfile ? (
              <BsCurrencyDollar fill="#3058A4" size="2rem" />
            ) : (
              <TbWorld color="#3058a4" size="2rem" />
            )}

            <p>{isAdminProfile ? usersWithBasicPlan : websitesUser.length}</p>
          </div>
        </D.NavCard>
      </Link>

      <Link
        to={
          isAdminProfile
            ? "/dashboard/usuarios"
            : "/dashboard/construtor-de-lojas"
        }
      >
        <D.NavCard>
          <h4>{isAdminProfile ? "Gold" : "Temas"}</h4>

          <div>
            {isAdminProfile ? (
              <BsCurrencyDollar fill="#3058A4" size="2rem" />
            ) : (
              <RiLayout3Line color="#3058a4" size="2rem" />
            )}
            <p>
              {isAdminProfile
                ? usersWithGoldPlan
                : themes.length > 0
                ? themes.length
                : 0}
            </p>
          </div>
        </D.NavCard>
      </Link>

      <Link
        to={isAdminProfile ? "/dashboard/usuarios" : "/dashboard/fornecedores"}
      >
        <D.NavCard>
          <h4>{isAdminProfile ? "Premium" : "Produtos"}</h4>
          <div>
            {isAdminProfile ? (
              <BsCurrencyDollar fill="#3058A4" size="2rem" />
            ) : (
              <BiStoreAlt color="#3058a4" size="2rem" />
            )}
            <p className={`number-transition ${isHidden ? "hidden" : ""}`}>
              {isAdminProfile ? usersWithPremiumPlan : quantityProducts}{" "}
            </p>
          </div>
        </D.NavCard>
      </Link>

      <Link
        to={isAdminProfile ? "/dashboard/usuarios" : "/dashboard/fornecedores"}
      >
        <D.NavCard>
          <h4>{isAdminProfile ? "Usuarios" : "Produtos em Alta"}</h4>
          <div>
            {isAdminProfile ? (
              <FaUser fill="#3058a4" size="1.5rem" />
            ) : (
              <FaFire color="#3058a4" size="2rem" />
            )}
            <p className={`number-transition ${isHidden ? "hidden" : ""}`}>
              {isAdminProfile ? users?.length : quantityProducts}
            </p>
          </div>
        </D.NavCard>
      </Link>

      <Link
        to={isAdminProfile ? "/dashboard/sites-admin" : "/dashboard/tutoriais"}
      >
        <D.NavCard>
          <h4>{isAdminProfile ? "Lojas" : "Tutoriais"}</h4>
          <div>
            {isAdminProfile ? (
              <RiLayout3Line color="#3058a4" size="2rem" />
            ) : (
              <FaCubes color="#3058a4" size="2rem" />
            )}
            <p>
              {isAdminProfile
                ? websitesAdmin?.length
                : tutorials !== undefined
                ? tutorials.length
                : 0}
            </p>
          </div>
        </D.NavCard>
      </Link>

      <Link
        to={
          isAdminProfile
            ? "/dashboard/fornecedores-admin"
            : "/dashboard/esteira-de-produtos"
        }
      >
        <D.NavCard>
          <h4>{isAdminProfile ? "Produtos" : "Esteira"}</h4>
          <div>
            <BiStoreAlt color="#3058a4" size="2rem" />
            <p>
              {isAdminProfile
                ? products
                  ? products?.length
                  : 0
                : productsConveyor
                ? productsConveyorQuantity
                : 0}
            </p>
          </div>
        </D.NavCard>
      </Link>
    </D.NavContainer>
  );
};
