import { Route, Routes } from "react-router-dom";

// Client side
import { Plans } from "../pages/Plans";
import { Config } from "../pages/Config";
import { Layout } from "../components/Layout";
import { Courses } from "../pages/ClientSide/Courses";
import { Tutorials } from "../pages/ClientSide/Tutorials";
import { Dashboard } from "../pages/Dashboard";
import { TutorialsInfo } from "../pages/ClientSide/Tutorials/TutorialsInfo";
import { BacklogPageAdmin } from "../pages/AdministrativePanel/BacklogPageAdmin";

// Admin
import { UsersAdmin } from "../pages/AdministrativePanel/UsersAdmin";
import { PlansAdmin } from "../pages/AdministrativePanel/PlansAdmin";
import { MySitesAdmin } from "../pages/AdministrativePanel/MySitesAdmin";
import { CategoryAdmin } from "../pages/AdministrativePanel/CategoryAdmin";
import { TutorialsAdmin } from "../pages/AdministrativePanel/TutorialsAdmin";
import { SuppliersAdmin } from "../pages/AdministrativePanel/SuppliersAdmin";
import { SocialMediaAdmin } from "../pages/AdministrativePanel/SocialMediaAdmin";
import { MyTemplatesAdmin } from "../pages/AdministrativePanel/MyTemplatesAdmin";

import { ProtectedRoute } from "./protectedRoute";

import { Login } from "../pages/Auth/Login";
import { Register } from "../pages/Auth/Register";
import { Products } from "../pages/Products";
import { CopyGenerator } from "../pages/CopyGenerator";
import { ProductInfo } from "../pages/Products/ProductInfo";
import { Calculator } from "../pages/Calculator";
import { ProductConveyor } from "../pages/ProductConveyor";
import { NameGenerator } from "../pages/NameGenerator";
import { Applications } from "../pages/Applications";
import { NotFound } from "../pages/NotFound";
import { RecoverPassword } from "../pages/Auth/RecoverPassword";
import { CreateNewPassword } from "../pages/Auth/CreateNewPassword";
import { StoreBuilder } from "../pages/StoreBuilder";
// import { TrendingProducts } from "../pages/TrendingProducts";
// import { TrendingProductInfo } from "../pages/TrendingProducts/TrendingProductInfo";
// import { RegisterVendors } from "../pages/RegisterVendors";

export const AppRoutes = ({ isProfileAdmin }) => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Login />
          </ProtectedRoute>
        }
      />

      <Route path="/*" element={<NotFound />} />

      <Route path="/cadastro" element={<Register />} />

      <Route path="/recuperar-senha" element={<RecoverPassword />} />

      <Route path="/nova-senha/:token" element={<CreateNewPassword />} />

      <Route path="/cadastro" element={<Register />} />

      <Route
        element={
          <ProtectedRoute>
            <Layout />
          </ProtectedRoute>
        }
      >
        <Route
          path="/dashboard/menu-inicial"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />

        {!isProfileAdmin && (
          <Route
            path="/dashboard/configuracoes"
            element={
              <ProtectedRoute>
                <Config />
              </ProtectedRoute>
            }
          />
        )}

        {!isProfileAdmin && (
          <Route
            path="/dashboard/calculadora"
            element={
              <ProtectedRoute>
                <Calculator />
              </ProtectedRoute>
            }
          />
        )}

        {!isProfileAdmin && (
          <Route
            path="/dashboard/calculadora/:productName/:productPrice"
            element={
              <ProtectedRoute>
                <Calculator />
              </ProtectedRoute>
            }
          />
        )}

        {!isProfileAdmin && (
          <Route
            path="/dashboard/copy"
            element={
              <ProtectedRoute>
                <CopyGenerator />
              </ProtectedRoute>
            }
          />
        )}

        {!isProfileAdmin && (
          <Route
            path="/dashboard/copy/:productName"
            element={
              <ProtectedRoute>
                <CopyGenerator />
              </ProtectedRoute>
            }
          />
        )}

        {!isProfileAdmin && (
          <Route
            path="/dashboard/gerador-de-nomes"
            element={
              <ProtectedRoute>
                <NameGenerator />
              </ProtectedRoute>
            }
          />
        )}

        {!isProfileAdmin && (
          <Route
            path="/dashboard/gerador-de-nomes/:name"
            element={
              <ProtectedRoute>
                <NameGenerator />
              </ProtectedRoute>
            }
          />
        )}

        {!isProfileAdmin && (
          <Route
            path="/dashboard/aplicativos"
            element={
              <ProtectedRoute>
                <Applications />
              </ProtectedRoute>
            }
          />
        )}

        {isProfileAdmin && (
          <Route
            path="/dashboard/sites-admin"
            element={
              <ProtectedRoute>
                <MySitesAdmin />
              </ProtectedRoute>
            }
          />
        )}

        {!isProfileAdmin && (
          <Route
            path="/dashboard/construtor-de-lojas"
            element={
              <ProtectedRoute>
                <StoreBuilder />
              </ProtectedRoute>
            }
          />
        )}

        {isProfileAdmin && (
          <Route
            path="/dashboard/midias-admin"
            element={
              <ProtectedRoute>
                <SocialMediaAdmin />
              </ProtectedRoute>
            }
          />
        )}

        {isProfileAdmin && (
          <Route
            path="/dashboard/templates-admin"
            element={
              <ProtectedRoute>
                <MyTemplatesAdmin />
              </ProtectedRoute>
            }
          />
        )}

        {isProfileAdmin ? (
          <Route
            path="/dashboard/fornecedores-admin"
            element={
              <ProtectedRoute>
                <SuppliersAdmin />
              </ProtectedRoute>
            }
          />
        ) : (
          <Route
            path="/dashboard/fornecedores"
            element={
              <ProtectedRoute>
                <Products />
              </ProtectedRoute>
            }
          >
            <Route
              path="/dashboard/fornecedores/:id"
              element={
                <ProtectedRoute>
                  <ProductInfo />
                </ProtectedRoute>
              }
            />
          </Route>
        )}

        {/* <Route
          path="/dashboard/produtos-em-alta"
          element={
            <ProtectedRoute>
              <TrendingProducts />
            </ProtectedRoute>
          }
        >
          <Route
            path="/dashboard/produtos-em-alta/:id"
            element={
              <ProtectedRoute>
                <TrendingProductInfo />
              </ProtectedRoute>
            }
          />
        </Route> */}

        {/* {isProfileAdmin && (
          <Route
            path="/dashboard/fornecedores-manuais"
            element={
              <ProtectedRoute>
                <RegisterVendors />
              </ProtectedRoute>
            }
          />
        )} */}

        {!isProfileAdmin && (
          <Route
            path="/dashboard/esteira-de-produtos"
            element={
              <ProtectedRoute>
                <ProductConveyor />
              </ProtectedRoute>
            }
          />
        )}

        {isProfileAdmin && (
          <Route
            path="/dashboard/categorias"
            element={
              <ProtectedRoute>
                <CategoryAdmin />
              </ProtectedRoute>
            }
          />
        )}

        {isProfileAdmin ? (
          <Route
            path="/dashboard/planos-admin"
            element={
              <ProtectedRoute>
                <PlansAdmin />
              </ProtectedRoute>
            }
          />
        ) : (
          <Route
            path="/dashboard/planos"
            element={
              <ProtectedRoute>
                <Plans />
              </ProtectedRoute>
            }
          />
        )}

        {isProfileAdmin && (
          <Route
            path="/dashboard/usuarios"
            element={
              <ProtectedRoute>
                <UsersAdmin />
              </ProtectedRoute>
            }
          />
        )}

        {isProfileAdmin ? (
          <Route
            path="/dashboard/tutoriais-admin"
            element={
              <ProtectedRoute>
                <TutorialsAdmin />
              </ProtectedRoute>
            }
          />
        ) : (
          <Route
            path="/dashboard/tutoriais"
            element={
              <ProtectedRoute>
                <Tutorials />
              </ProtectedRoute>
            }
          >
            <Route
              path="/dashboard/tutoriais/:id"
              element={
                <ProtectedRoute>
                  <TutorialsInfo />
                </ProtectedRoute>
              }
            />
          </Route>
        )}

        <Route
          path="/dashboard/cursos"
          element={
            <ProtectedRoute>
              <Courses />
            </ProtectedRoute>
          }
        />

        {isProfileAdmin && (
          <Route
            path="/dashboard/pendencias"
            element={
              <ProtectedRoute>
                <BacklogPageAdmin />
              </ProtectedRoute>
            }
          />
        )}
      </Route>
    </Routes>
  );
};
